
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatableLeft.vue';
import TargetDrawer from '@/layout/header/partials/trainning_institute/InstituteTargetDrawer.vue';
//import TargetModal from "@/components/modals/forms/institute/InstituteTarget.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-institute-target-list',
  components: {
    Form,
    Field,
    Datatable,
    TargetDrawer,
    Multiselect,
  },
  data() {
    return {
      batch: {
        entity_id: [] as any,
        tranche_id: [] as any,
        institute_id: '',
        course_id: '',
      } as any,
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [] as any,
      course_Info: [],
      courseList: [],
      tranches_info: [],
      batchInfo: [],
      instituteList: [],
      componentKey: 0,
      divisions: [],
      districts: [],
       optionsTPartner: [] as any,
      districts_data: [] as any,
      associationData: [] as any,
      TrancheInfos: [] as any,
      TpartnerInfos: [] as any,
      TpartnerLabel: [] as any,
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'Institute',
          key: 'institute',
          sortable: true,
        },
        {
          name: 'Target Trainee',
          key: 'target_trainee',
          sortable: true,
        },
        {
          name: 'Batch Size',
          key: 'batch_size',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          name: '# of Batches',
          key: 'of_batch',
          sortable: true,
        },
      ],
      institute_name: '',
      institute_id: '',
      data: {},
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      load: false,
      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      optionsTranche: [] as any,
      entityIds: [] as any,
    };
  },
  async created() {
    this.emitter.on('course-updated', async () => {
      await this.courseInfo();
    });
      await this.getTrainingInstitute();
    await this.getAssociation();
    await this.getTranche();
  },
   watch: {
    "batch.entity_id": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } 
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_id', this.batch.institute_id);

      await ApiService.post('institutetarget/institutetargetlistpdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
     let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_id', this.batch.institute_id);

      axios
        .post(`${this.VUE_APP_API_URL}/api/institutetarget/institutetargetlistprint`, formData)
        .then(response => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
    let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } 
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_id', this.batch.institute_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/institutetarget/institutetargetlistexport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'targetlist.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    filterEntity(entity) {
      this.getTrainingInstitute();
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.entityInfos.shift();

        this.entityInfos.forEach((val, index) => {
          if (val.id != 'select') {
            this.batch.entity_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.entityInfos = [];
        this.entityInfos.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.entityInfos = [];

        this.entityInfos = this.tempAssociation;
        this.entityInfos.unshift(this.selectObj);
        this.batch.entity_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async formSubmit() {
      //form submit
    },
    async getAssociation() {
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id =  this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    // async courseInfo() {
    //   await ApiService.get(
    //     this.VUE_APP_COURSE_SEARCH_LIST_API +
    //       "?entity_id=" +
    //       this.batch.entity_id +
    //       "&tranche=" +
    //       this.batch.tranche_id +
    //       "&institute_info_id=" +
    //       this.batch.institute_id
    //   )
    //     .then((response) => {
    //       console.log(response);
    //       this.courseList = response.data.data;
    //       this.componentKey += 1;
    //       this.showCourseNotice = true;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    async courseInfo() {
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } 
      if (this.batch.tranche_id.length > 0 && this.batch.entity_id.length > 0) {
        this.load = true;
        await ApiService.get(
          'institutetarget/list?tranche_id=' +
          this.batch.tranche_id +
          '&entity_id=' +
          entity_id +
          '&training_institute_id=' +
          this.batch.institute_id + '&active_status=1'
        )
          .then((response) => {
            this.courseList = response.data.data;
            // this.institute_name = response.data.data.institute_name;
            // this.institute_id = response.data.data.institute_id;
            this.componentKey += 1;
            this.showCourseNotice = true;
            this.load = false;
          })
          .catch((response) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please Select Tranche and Training Partner',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    edit(data) {
      // data.institute_id=this.institute_id;
      // data['institute_id']=this.institute_id;
      this.emitter.emit('institute_target_add', data);
    },

    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
